/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/ace-builds@1.23.4/src-noconflict/ace.min.js
 * - /npm/ace-builds@1.23.4/src-min-noconflict/theme-monokai.min.js
 * - /npm/ace-builds@1.23.4/src-min-noconflict/mode-html.min.js
 * - /npm/ace-builds@1.23.4/src-min-noconflict/mode-css.min.js
 * - /npm/ace-builds@1.23.4/src-min-noconflict/mode-javascript.min.js
 * - /npm/ace-builds@1.23.4/src-min-noconflict/ext-beautify.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
